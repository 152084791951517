<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box">
        <div class="top">
          <ImgDecypt v-if="topicInfo.cover" class="top-img" :src="topicInfo.cover"/>
          <div class="top-back" @click="$router.go(-1)">
            <div class="left"></div>
          </div>
        </div>
        <div class="bottom flex">
          <ImgDecypt v-if="topicInfo.avatar" class="bottom-img" :src="topicInfo.avatar"/>#{{topicInfo.name}}
        </div>
      </div>
      <div class="section">
        <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="videoList.length>0">
          <div v-for="(item,index) in videoList" :key="item.id">
            <dynamiclist :item="item" @cared="cared" @shareShow="shareS(item,index)" @commentShow="commentS(item,index)" @membershipShow="memberShow(item)"/>
            <AdvSwiper :advList="advList" v-if="(index + 1) % 4 == 0 && advList.length > 0" class="l_adv"/>
          </div>
        </pullrefresh>
        <van-loading type="spinner" color="#f21313" v-show="videoList&&videoList.length==0 && !noData"/>
        <nodata v-show="noData"/>
      </div>
    </div>
    <comment v-show="commentShow" :topicInfo="memberInfo" @commentSuccess="commentSuccess" :commentShow="commentShow" @commentClose="commentShow=false"/>
    <membership v-show="membershipShow" :topicInfo="memberInfo" :membershipShow="membershipShow" @membershipClose="membershipShow=false"/>
    <share v-if="shareShow" :topicInfo="memberInfo" @shareSuccess="shareSuccess" :shareShow="shareShow" @shareClose="shareShow=false"/>
  </div>
</template>
<script>
import share from '../widget/share.vue';
import comment from '../widget/comment.vue';
import membership from '../widget/membership';
import dynamiclist from '../widget/dynamicList.vue';
import pullrefresh from "@/components/PullRefresh/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import {getTopic} from '@/api/app.js';
export default {
  components:{
    dynamiclist,
    pullrefresh,
    comment,
    membership,
    share,
    AdvSwiper
  },
  data(){
    return{
      pageNum:1,//页码
      noData:false,//暂无数据
      videoList:[],//有关话题列表
      topicInfo:{},//有关话题信息
      index:0,//话题选中的项
      commentShow:false,//评论是否显示
      membershipShow:false,//是否显示开通会员弹窗
      shareShow:false,//分享组件的显示与否
      memberInfo:{},//开通会员弹窗需要的信息
      advList:[]
    }
  },
  created(){
    this.advList = getAdItem(AdType.seArea);
    this.getTopic();
  },
  methods:{
    cared(id){//博主关注成功回调 将图文列表的该作者的所有动态的关注状态改变
      this.videoList.forEach((item) => {
        if(item.author.id == id){
          item.author.cared = true;//改变该博主的关注状态
        }
      });
    },
    shareS(item,index){//监听分享点击事件
      this.index = index;
      this.shareShow = true;
      this.memberInfo = item;
    },
    shareSuccess(){//分享成功回调
      this.videoList[this.index].shareNum ++;
    },
    commentSuccess(){//评论成功评论条数加一
      this.videoList[this.index].commentNum ++;
    },
    commentS(item,index){//监听评论点击事件
      this.index = index;
      this.commentShow = true;
      this.memberInfo = item;
    },
    memberShow(item){//监听图片的点击事件
      this.membershipShow = true;
      this.memberInfo = item;
    },
    async getTopic(){//通过接口得到视频列表
      let req = {
        topic:this.$route.query.name,
        pageNum:this.pageNum,
        pageSize:10,
      }
      let ret = await this.$Api(getTopic,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        this.topicInfo = ret.data.topicInfo;
        let list = ret.data.mediaInfos?ret.data.mediaInfos:[]
        this.videoList = this.videoList.concat(list);
        if(list.length < 10){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    onLoad(){
      this.pageNum ++;
      this.getTopic();
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.top{
  height:180px;
  position: relative;
}
.top-img{
  width:100%;
  height:100%;
}
.left{
  position: absolute;
  top:12px;
  left:10px;
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.section{
  height: calc(100% - 227px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.l_adv{
  padding-top: 10px;
  height: 100px;
  overflow: hidden;
  /deep/ .swiperSlide {
    height: 100px;
  }
}
.bottom{
  height:46px;
  padding:0 16px;
  font-size:14px;
  border-bottom:1px solid #e0e5eb;
}
.bottom-img{
  width:63px;
  height:63px;
  border-radius:4px;
  margin-right:7px;
  vertical-align: middle;
  margin-top:-26px;
  overflow: hidden;
}
.top-back{
  position: absolute;
  width: 100%;
  left:0;
  top:0;
  height:52px;
}
</style>
